import React, { useContext } from 'react';
import { FlatList, Text, View } from 'react-native';

import { Checkbox } from '@/components/Checkbox';
import { InterviewContext } from '@/contexts/InterviewContext';
import type { TypeAnswerModel } from '@/database/model/TypeAnswer';

import { styles } from '../styles';

interface IProps {
  values: TypeAnswerModel[];
  onChange: (value: TypeAnswerModel) => void;
  answerLimit: number | null;
}

export function CheckboxGroup({ values, onChange, answerLimit }: IProps) {
  const { answeredQuestions } = useContext(InterviewContext);

  const isChecked = (value: string) => {
    return answeredQuestions.some((question) =>
      question.values.includes(value),
    );
  };

  const answerBlocked = (item: TypeAnswerModel) => {
    const selected = answeredQuestions
      .filter((answer) => answer.question_id === item.question_id)
      .some((answer) => {
        const select = answer.values.find((value) => value === item.id);
        const filtered = values
          .filter((value) => answer.values.includes(value.id))
          .some((value) => value.is_unique);

        if (
          !!Number(answerLimit) &&
          answer.values.length >= Number(answerLimit) &&
          !select
        ) {
          return true;
        }

        if (answer.values?.length === 0) {
          return false;
        }
        if (select) {
          return false;
        }

        if (!select && item.is_unique) {
          return true;
        }

        if (!select) {
          return filtered;
        }
        return false;
      });

    return selected;
  };

  const orderSelected = (value: string) => {
    let order: number = -1;
    answeredQuestions.forEach((question) => {
      const find = question.values.findIndex((item) => item === value);
      if (find !== -1) {
        order = find + 1;
      }
    });
    return order;
  };

  return (
    <FlatList
      data={values}
      style={{ gap: 12 }}
      renderItem={({ item }) => (
        <View style={{ position: 'relative' }}>
          <Checkbox
            label={item.value}
            value={isChecked(item?.id)}
            answerData={item}
            onChange={onChange}
            disabled={answerBlocked(item)}
          />
          {isChecked(item?.id) && (
            <Text style={styles.orderMultiple}>{orderSelected(item.id)}</Text>
          )}
        </View>
      )}
    />
  );
}
